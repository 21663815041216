<template>

  <div>
    <b-sidebar
      id="add-new-bank-account-sidebar"
      :visible="showEditSidebar"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      width="700px"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-product-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $t('Banka Hesabı Ekle') }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(saveData)"
            @reset.prevent="resetForm"
          >

            <!-- Firma ad -->
            <validation-provider
              #default="validationContext"
              :name="$t('Banka Adı')"
              rules="required|min:2"
            >
              <b-form-group
                :label="$t('Banka Adı')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="selectedBankAccount.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              :label="$t('Dil')"
              label-for="lang"
            >
              <b-form-radio
                v-model="selectedBankAccount.lang"
                name="lang"
                value="tr"
              >
                {{ $t('Türkçe') }}
              </b-form-radio>

            </b-form-group>
            <b-form-group>

              <b-form-radio
                v-model="selectedBankAccount.lang"
                name="lang"
                value="en"
              >
                {{ $t('İngilizce') }}
              </b-form-radio>
            </b-form-group>
            <hr>
            <b-form-group
              :label="$t('Hesap Bilgileri')"
              label-for="description"
            >
              <quill-editor
                id="description"
                v-model="selectedBankAccount.description"
                :options="snowOption"
              />
            </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ $t('Kaydet') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t('Vazgeç') }}
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <b-row>
      <b-col md="12">
        <b-card
          class="mb-0"
        >

          <b-row>
            <b-col md="12">
              <div class="d-flex align-items-center justify-content-end">

                <b-button
                  size="sm"
                  variant="primary"
                  @click="openEditSidebarForAdd"
                >
                  {{ $t('Banka Hesabı Ekle') }}
                </b-button>
              </div>
              <hr>
              <b-list-group>
                <b-list-group-item
                  v-for="(item,index) in bankAccounts"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >{{ item.name }}
                  <b-dropdown
                    variant="link"
                    no-caret
                    size="sm"
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="12"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item @click="openEditSidebarForEditing(item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">{{ $t('Düzenle') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      variant="danger"
                      @click="deleteData(index,item.id)"
                    >
                      <feather-icon icon="Trash2Icon" />
                      <span class="align-middle ml-50">{{ $t('Sil') }}</span>
                    </b-dropdown-item>
                  </b-dropdown></b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import {
  BCard, BRow, BCol, VBModal, BListGroup, BFormRadio, BListGroupItem, BButton, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import proposalStoreModule from '../proposalsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    BButton,
    BListGroupItem,
    BFormInvalidFeedback,
    BSidebar,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormRadio,
    quillEditor,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      showMarkAsReturnedModal: false,
      returnCompletionDate: '',
      showEditSidebar: false,
    }
  },
  methods: {
    openEditSidebarForEditing(item) {
      this.selectedBankAccount = JSON.parse(JSON.stringify(item))
      this.showEditSidebar = true
    },
    openEditSidebarForAdd() {
      this.showEditSidebar = true
      this.selectedBankAccount = JSON.parse(JSON.stringify(this.blankBankAccountData))
    },
    // eslint-disable-next-line no-unused-vars
    saveData() {
      if (this.selectedBankAccount.id !== 0) {
        store.dispatch('app-proposal/updateBankAccount', this.selectedBankAccount)
          .then(response => {
            this.bankAccounts.forEach((val, index) => {
              if (val.id === this.selectedBankAccount.id) {
                this.bankAccounts[index] = response.data.data
              }
            })
            this.showEditSidebar = false
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      } else {
        store.dispatch('app-proposal/addBankAccount', this.selectedBankAccount)
          .then(response => {
            this.bankAccounts.push(response.data.data)
            this.showEditSidebar = false
            this.showSuccessMessage()
          })
          .catch(() => {
            this.showErrorMessage()
          })
      }
    },
    // eslint-disable-next-line no-unused-vars
    deleteData(index, id) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Banka Hesabını Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-proposal/deleteBankAccount', { id })
            .then(() => {
              this.bankAccounts.splice(index, 1)
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },

    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-proposal'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, proposalStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const bankAccounts = ref([])
    const selectedBankAccount = ref(null)
    const blankBankAccountData = {
      id: 0,
      name: '',
      description: '',
      lang: 'tr',

    }
    const fetchBankAccounts = () => {
      store.dispatch('app-proposal/fetchBankAccounts', {})
        .then(response => {
          const { data } = response.data
          bankAccounts.value = data
        })
        .catch(() => {
          alert('Bilgiler getirilirken hata oluştu')
        })
    }
    fetchBankAccounts()

    const resetBankAccountData = () => {
      selectedBankAccount.value = JSON.parse(JSON.stringify(blankBankAccountData))
    }
    resetBankAccountData()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBankAccountData)
    return {
      bankAccounts,
      blankBankAccountData,
      fetchBankAccounts,
      refFormObserver,
      getValidationState,
      selectedBankAccount,
      resetForm,
      snowOption: {
        theme: 'snow',
      },
    }
  },
}
</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
#add-new-bank-account-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
