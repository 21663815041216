import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Proposal standard clauses
    addStandardClause(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/proposal-standard-clauses', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStandardClause(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/proposal-standard-clauses/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStandardClause(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/proposal-standard-clauses/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStandardClauses(ctx, { lang }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/proposal-standard-clauses/${lang}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStandardClauseOrdering(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/standard-clauses-change-ordering', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // -------Proposal standard clauses END --------------
    // Bank Accounts --------------------
    addBankAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/bank-accounts', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBankAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/bank-accounts/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBankAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/bank-accounts/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBankAccounts(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/bank-accounts', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // -------Bank Accounts --------------

    // -----Proposals-----------------
    fetchProposals(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/proposals', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProposal(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/proposals/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProposal(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/proposals', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProposal(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/proposals/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // -----------Proposals end-------------------

    fetchProposalMailContent(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/proposal-mail-content', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMail(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/proposal-mail-send', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
