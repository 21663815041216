<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >

    <!-- Sabit Teklif Maddeleri -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Sabit Teklif Maddeleri') }}</span>
      </template>

      <proposal-settings-clauses />
    </b-tab>
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BriefcaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('Banka Hesapları') }}</span>
      </template>

      <proposal-settings-bank-accounts />
    </b-tab>
    <!--/ Sabit Teklif Maddeleri -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ProposalSettingsClauses from './ProposalSettingsClauses.vue'
import ProposalSettingsBankAccounts from './ProposalSettingsBankAccounts.vue'

export default {
  components: {
    BTabs,
    BTab,
    ProposalSettingsClauses,
    ProposalSettingsBankAccounts,
  },
  data() {
    return {
      options: {},
    }
  },
  beforeCreate() {

  },
}
</script>
