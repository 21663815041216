<template>

  <div>
    <b-row>
      <b-col md="12">
        <b-card
          class="mb-0"
        >

          <b-row>
            <b-col md="12">
              <b-tabs>

                <b-tab :title="$t('Türkçe Teklif Maddeleri')">
                  <div class="d-flex align-items-center justify-content-end">

                    <b-button
                      size="sm"
                      variant="primary"
                      @click="addNew('tr')"
                    >
                      {{ $t('Yeni Teklif Maddesi Ekle') }}
                    </b-button>
                  </div>
                  <hr>
                  <draggable
                    v-model="clausesTr"
                    class="list-group list-group-flush cursor-move"
                    tag="ul"
                    @end="moveElement('tr')"
                  >
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <b-list-group-item
                        v-for="(item,index) in clausesTr"
                        :key="index"
                        tag="li"
                        :variant="item.text==''?'danger':''"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-form-input
                          v-if="item.edit_mode"
                          v-model="item.text_edit"
                          :placeholder="$t('Teklif Maddesi Giriniz')"
                        />
                        <div class="d-flex">

                          <div
                            v-if="!item.edit_mode"
                            class="ml-25"
                          >

                            <b-card-text

                              class="font-weight-bold mb-0"
                            >
                              {{ item.text }}
                            </b-card-text>
                          </div>
                        </div>
                        <b-button-toolbar v-if="item.edit_mode">
                          <b-button-group>
                            <b-button
                              v-if="item.text_edit!=''"
                              variant="outline-primary"
                              @click="saveData(item,'tr')"
                            >
                              <feather-icon icon="SaveIcon" />
                            </b-button>
                            <b-button
                              v-if="item.text_edit!=''"
                              variant="outline-primary"
                              @click="item.edit_mode=false"
                            >
                              <feather-icon icon="XIcon" />
                            </b-button>
                            <b-button
                              variant="outline-primary"
                              @click="deleteData(item,index,'tr')"
                            >
                              <feather-icon icon="Trash2Icon" />
                            </b-button>
                          </b-button-group>
                        </b-button-toolbar>
                        <b-dropdown
                          v-if="!item.edit_mode"
                          variant="link"
                          no-caret
                          size="sm"
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="12"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item @click="item.edit_mode=true; item.text_edit=item.text">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Düzenle') }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            variant="danger"
                            @click="deleteData(index,item.id,'tr')"
                          >
                            <feather-icon icon="Trash2Icon" />
                            <span class="align-middle ml-50">{{ $t('Sil') }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-list-group-item>
                    </transition-group>
                  </draggable>
                </b-tab>
                <b-tab :title="$t('İngilizce Teklif Maddeleri')">
                  <div class="d-flex align-items-center justify-content-end">

                    <b-button
                      size="sm"
                      variant="primary"
                      @click="addNew('en')"
                    >
                      {{ $t('Yeni Teklif Maddesi Ekle') }}
                    </b-button>
                  </div>
                  <hr>
                  <draggable
                    v-model="clausesTr"
                    class="list-group list-group-flush cursor-move"
                    tag="ul"
                    @end="moveElement('en')"
                  >
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <b-list-group-item
                        v-for="(item,index) in clausesEn"
                        :key="index"
                        tag="li"
                        :variant="item.text==''?'danger':''"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-form-input
                          v-if="item.edit_mode"
                          v-model="item.text_edit"
                          :placeholder="$t('Teklif Maddesi Giriniz')"
                        />
                        <div class="d-flex">

                          <div
                            v-if="!item.edit_mode"
                            class="ml-25"
                          >

                            <b-card-text

                              class="font-weight-bold mb-0"
                            >
                              {{ item.text }}
                            </b-card-text>
                          </div>
                        </div>
                        <b-button-toolbar v-if="item.edit_mode">
                          <b-button-group>
                            <b-button
                              v-if="item.text_edit!=''"
                              variant="outline-primary"
                              @click="saveData(item,'en')"
                            >
                              <feather-icon icon="SaveIcon" />
                            </b-button>
                            <b-button
                              v-if="item.text_edit!=''"
                              variant="outline-primary"
                              @click="item.edit_mode=false"
                            >
                              <feather-icon icon="XIcon" />
                            </b-button>
                            <b-button
                              variant="outline-primary"
                              @click="deleteData(item,index,'en')"
                            >
                              <feather-icon icon="Trash2Icon" />
                            </b-button>
                          </b-button-group>
                        </b-button-toolbar>
                        <b-dropdown
                          v-if="!item.edit_mode"
                          variant="link"
                          no-caret
                          size="sm"
                          :right="$store.state.appConfig.isRTL"
                        >
                          <template #button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="12"
                              class="align-middle text-body"
                            />
                          </template>
                          <b-dropdown-item @click="item.edit_mode=true; item.text_edit=item.text">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{ $t('Düzenle') }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item
                            variant="danger"
                            @click="deleteData(index,item.id,'en')"
                          >
                            <feather-icon icon="Trash2Icon" />
                            <span class="align-middle ml-50">{{ $t('Sil') }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-list-group-item>
                    </transition-group>
                  </draggable>
                </b-tab>
              </b-tabs>

            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBModal, BTabs, BTab, BButton, BCardText, BListGroupItem, BDropdown, BDropdownItem, BFormInput, BButtonToolbar, BButtonGroup,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import draggable from 'vuedraggable'
import proposalStoreModule from '../proposalsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    draggable,
    BTabs,
    BTab,
    BButton,
    BCardText,
    BListGroupItem,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BButtonToolbar,
    BButtonGroup,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      showMarkAsReturnedModal: false,
      returnCompletionDate: '',
      selectedId: 0,
    }
  },
  methods: {
    addNew(lang) {
      const clauseData = JSON.parse(JSON.stringify(this.blankClauseData))
      clauseData.lang = lang
      store.dispatch('app-proposal/addStandardClause', clauseData)
        .then(response => {
          const { data } = response.data
          data.text_edit = data.text
          data.edit_mode = true
          if (lang === 'en') {
            this.clausesEn.push(data)
          } else if (lang === 'tr') {
            this.clausesTr.push(data)
          }
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    // eslint-disable-next-line no-unused-vars
    saveData(item, lang) {
      const formData = JSON.parse(JSON.stringify(item))
      formData.text = formData.text_edit
      store.dispatch('app-proposal/updateStandardClause', formData)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          item.text = item.text_edit
          // eslint-disable-next-line no-param-reassign
          item.edit_mode = false
          this.showSuccessMessage()
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    // eslint-disable-next-line no-unused-vars
    deleteData(index, id, lang) {
      this.$swal({
        title: this.$t('Emin Misiniz ?'),
        text: this.$t('Bu Standard Teklif Maddesini Silmek İstediğinizden Emin Misiniz ? (Bu işlem geri alınamaz.)'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Silme İşlemini Onayla'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('app-proposal/deleteStandardClause', { id })
            .then(() => {
              if (lang === 'tr') {
                this.clausesTr.splice(index, 1)
              } else if (lang === 'en') {
                this.clausesEn.splice(index, 1)
              }
              this.showSuccessMessage()
            })
            .catch(() => {
              this.showErrorMessage()
            })
        }
      })
    },
    moveElement(lang) {
      const newOrderingData = []
      if (lang === 'tr') {
        this.clausesTr.forEach(val => {
          newOrderingData.push({ id: val.id })
        })
      } else if (lang === 'en') {
        this.clausesEn.forEach(val => {
          newOrderingData.push({ id: val.id })
        })
      }
      store.dispatch('app-proposal/changeStandardClauseOrdering', { ordering_data: newOrderingData })
        .then(() => {
        })
        .catch(() => {
          this.showErrorMessage()
        })
    },
    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-proposal'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, proposalStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const clausesTr = ref([])
    const clausesEn = ref([])
    const fetchClauses = lang => {
      store.dispatch('app-proposal/fetchStandardClauses', { lang })
        .then(response => {
          const { data } = response.data
          data.forEach(val => {
          // eslint-disable-next-line no-param-reassign
            val.edit_mode = false
            // eslint-disable-next-line no-param-reassign
            val.text_edit = val.text
          })
          if (lang === 'tr') {
            clausesTr.value = data
          } else if (lang === 'en') {
            clausesEn.value = data
          }
        })
        .catch(() => {
          this.showErrorMessage()
        })
    }
    fetchClauses('tr')
    fetchClauses('en')

    const blankClauseData = {
      id: 0,
      text: '',
      text_edit: '', // Düzenleme modu açıldığında orjinal metin buraya girilecek ve inputta gösterilcek eğer kaydedilierse text alanına alınacak.
      lang: '',
      ordering: 1,
      edit_mode: true,

    }
    return {
      clausesEn,
      clausesTr,
      blankClauseData,
      fetchClauses,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.list-group-item {
  transition: all 1s
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
